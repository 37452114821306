import Carousel from 'react-bootstrap/Carousel';
import { AppName, SubTitulo } from '../../constantes';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
function Vacinas() {
  return (
    <>
      <Container>
        <img src="/vacinas1.png"></img>
      </Container>
    </>
  );
}
export default Vacinas;
