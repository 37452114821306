import Carousel from 'react-bootstrap/Carousel';
import { AppName, SubTitulo } from '../../constantes';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
function BemEstar() {
  return (
    <>
      <Container>
        <img src="/boa-viagem.jpg"></img>
      </Container>
    </>
  );
}
export default BemEstar;
